<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Check-in Records"
      class="px-5 py-3"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              lg="7"
              class="text-right"
            >
              <v-btn
                v-if="showFilterBtn"
                @click="clearFilter()"
              >
                Clear Filter
              </v-btn>
              <v-btn
                color="#FF3700"
                @click="filterRecords()"
              >
                Filter
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              lg="1"
              class="text-right"
            >
              <download-excel
                :data="records"
                :fields="jsonFields"
                worksheet="SwiftCheckinRecords"
                :name="getExportSheetName"
                :button_text="btnName"
              >
                <v-btn>
                  Export
                </v-btn>
              </download-excel>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :items-per-page="recordsTable.itemsPerPage"
          :headers="recordsTable.headers"
          :items="records"
          :single-expand="singleExpand"
          :search="search"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          class="elevation-1 clickable"
        >
          <template
            v-slot:item="{ item, expand, isExpanded }"
          >
            <tr>
              <td>{{ item.swift_user.first_name +' '+ item.swift_user.last_name }}</td>
              <td>{{ item.check_in_time | convertStringToLocalDatetime }}</td>
              <td>
                <p v-if="item.check_out_time">
                  {{ item.check_out_time | convertStringToLocalDatetime }}
                </p>
                <p v-else>
                  -
                </p>
              </td>
              <td>
                {{ item.type }}
              </td>
              <td>
                {{ item.digi_board.location }}
              </td>
              <td>
                {{ item.updated_at | diffHumans }}
              </td>
              <td>
                <v-btn
                  icon
                  small
                  @click="expand(!isExpanded)"
                >
                  <v-icon
                    v-if="!isExpanded"
                    @click="getRecordDetails(item.id)"
                  >
                    mdi-arrow-down-drop-circle
                  </v-icon>
                  <v-icon v-if="isExpanded">
                    mdi-arrow-up-drop-circle
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length">
              <div class="row">
                <div class="col-4 text-left">
                  <div
                    v-for="(questionAnswer, index) in selectedRecord.question_answers"
                    :key="index"
                  >
                    <b>Q:</b> {{ questionAnswer.question }}
                    <b>A:</b> {{ questionAnswer.answer_selected }}
                  </div>
                </div>
                <div class="col-4 text-left">
                  <b>Reported To:</b> {{ selectedRecord.reported_to }}
                </div>
                <div class="col-4 text-left">
                  <b>Department:</b> {{ selectedRecord.department }}
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </base-material-card>

    <centre-spinner
      :loading="loading"
    />

    <filter-record
      v-if="showFilterRecord"
      @closed="showFilterRecord = false"
      @filterCompleted="onFilterCompleted()"
    />
  </v-container>
</template>

<script>
  import axios from 'src/app-axios';
  import constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import FilterRecord from './FilterRecord.vue';
  import JsonExcel from 'vue-json-excel';

  export default {

    name: 'CheckinRecords',

    components: {
      'centre-spinner': spinner,
      'filter-record': FilterRecord,
      'download-excel': JsonExcel,
    },

    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      prettyJson (val) {
        return JSON.stringify(val);
      },
    },

    data: () => ({
      records: [],
      recordsTable: {
        headers: [
          { text: 'Name', align: 'left', value: 'swift_user.first_name' },
          { text: 'Check-in', value: 'check_in' },
          { text: 'Check-out', value: 'check_out' },
          { text: 'Type', value: 'type' },
          { text: 'Site', value: 'digi_board.location' },
          { text: 'Last Updated', value: 'updated_at' },
          { text: '', value: 'data-table-expand' },
        ],
        itemsPerPage: 10,
      },
      expanded: [],
      singleExpand: true,
      jsonFields: {
        'First Name': 'swift_user.first_name',
        'Last Name': 'swift_user.last_name',
        'Check In Time': {
          field: 'check_in_time',
          callback: (value) => {
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        'Check Out Time': {
          field: 'check_out_time',
          callback: (value) => {
            if (value === null || value === '') {
              return '-';
            }
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        Type: 'type',
        'Onsite Hours': 'onsite_hours',
        Location: 'digi_board.location',
        'Last Updated At': {
          field: 'updated_at',
          callback: (value) => {
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
      },
      btnName: 'Export',
      search: '',
      loading: false,
      showFilterRecord: false,
      showFilterBtn: false,
      selectedRecord: {},
    }),

    computed: {
      getExportSheetName () {
        return 'swift_checkin_records_' + moment().format('DD-MM-YYYY-hh-mm') + '.xls';
      },
    },

    created () {
      this.loading = true;
      this.getAllRecords();
    },

    methods: {
      getAllRecords () {
        axios.get('api/swift-checkin/records').then(response => {
          this.records = response.data;
          this.$store.dispatch('records/onRecordsAdd', response.data);
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Something went wrong in getting checkin records.',
            type: constants.ALERT_TYPE_ERROR,
          });
        });
      },

      getRecordDetails (recordId) {
        const uri = 'api/swift-checkin/records/' + recordId;
        this.loading = true;
        axios.get(uri).then(response => {
          this.selectedRecord = response.data;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Something went wrong in getting checkin record details.',
            type: constants.ALERT_TYPE_ERROR,
          });
        });
      },

      filterRecords () {
        this.showFilterRecord = true;
      },

      onFilterCompleted () {
        this.records = [];
        this.records = this.$store.getters['records/getRecords'];
        this.showFilterBtn = true;
      },

      clearFilter () {
        this.loading = true;
        this.records = [];
        this.getAllRecords();
        this.loading = false;
        this.showFilterBtn = false;
      },
    },
  };
</script>
