<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="500"
    >
      <v-form>
        <v-card>
          <v-card-title>
            Filter Record

            <v-spacer />

            <v-icon
              aria-label="Close"
              @click="showModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="text-body-1 text-center">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="filteringData.firstName"
                    label="First Name"
                    hint="Contractor's first name"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="filteringData.lastName"
                    label="Last Name"
                    hint="Contractor's last name"
                    outlined
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-datetime-picker
                    v-model="filteringData.checkInTime"
                    label="From"
                    date-format="yyyy-MM-dd"
                    time-format="HH:mm"
                    :text-field-props="{
                      suffix: 'AEST',
                      class: 'custom-label-color',
                      outlined: true,
                    }"
                    :date-picker-props="{
                      headerColor: '#FF3700',
                      max: maxStartDate
                    }"
                    :time-picker-props="{
                      headerColor: '#FF3700'
                    }"
                  >
                    <template v-slot:dateIcon>
                      <v-icon
                        large
                      >
                        mdi-calendar
                      </v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon large>
                        mdi-alarm
                      </v-icon>
                    </template>
                  </v-datetime-picker>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-datetime-picker
                    v-model="filteringData.checkOutTime"
                    label="To"
                    :text-field-props="{
                      persistentHint: true,
                      suffix: 'AEST',
                      outlined: true,
                    }"
                    :date-picker-props="{
                      headerColor: '#FF3700',
                      min: minEndDate
                    }"
                    :time-picker-props="{
                      headerColor: '#FF3700'
                    }"
                  >
                    <template v-slot:dateIcon>
                      <v-icon large>
                        mdi-calendar
                      </v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon large>
                        mdi-alarm
                      </v-icon>
                    </template>
                  </v-datetime-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="filteringData.type"
                    :items="possibleTypes"
                    label="type"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              depressed
              default
              @click="cancel()"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              depressed
              default
              @click="submit()"
            >
              Filter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import moment from 'moment';
  import axios from 'src/app-axios';
  import constants from 'src/constants';

  export default {
    data: () => ({
      filteringData: {
        firstName: null,
        lastName: null,
        checkInTime: null,
        checkOutTime: null,
        type: null,
      },
      possibleTypes: ['Contractor', 'Visitor', 'Staff'],
      showModal: true,
      maxStartDate: moment().format('YYYY-MM-DD'),
    }),

    computed: {
      minEndDate () {
        return moment(this.start).format('YYYY-MM-DD');
      },
    },

    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },

    methods: {
      cancel () {
        this.showModal = false;
      },

      submit () {
        axios.get('api/swift-checkin/records/filter', {
          params: this.filteringData,
        }).then(response => {
          this.$store.dispatch('records/onRecordsAdd', response.data);
          this.showModal = false;
          this.$emit('filterCompleted');
        }).catch(() => {
          this.showModal = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Something went wrong in filtering records.',
            type: constants.ALERT_TYPE_ERROR,
          });
        });
      },
    },
  };
</script>
